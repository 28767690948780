import ReceiptCounter from '@components/receipt-counter'
import * as React from 'react'
import { Container } from 'react-bootstrap'

const ReceiptCounterFooter = ({
  sectionBackGround
}: {
  sectionBackGround: string
}) => (
  <section style={{ backgroundColor: sectionBackGround }}>
    <div className="curve-border" style={{ backgroundColor: '#263863' }}>
      <Container className="p-0">
        <div
          className="d-flex justify-content-center w-100"
          style={{ padding: `4rem 0px` }}
        >
          <div className="d-flex justify-content-center align-items-center flex-column">
            <h5 className="fw-light" style={{ color: 'rgb(138,178,225)' }}>
              Our Data
            </h5>
            <h1 className={'text-white fw-bold'} style={{ fontSize: 60 }}>
              <ReceiptCounter />
            </h1>
            <h4 className="fw-light" style={{ color: 'rgb(138,178,225)' }}>
              RECEIPTS UPLOADED
            </h4>
          </div>
        </div>
      </Container>
    </div>
  </section>
)

export default ReceiptCounterFooter
