import React, { useRef } from 'react'
import { useCountUp } from 'react-countup'

const baseTime = new Date('2021-07-31T13:21:00Z')
const baseReceipts = 7275995
const getReceiptNumber = () => {
  const seconds = (new Date().getTime() - baseTime.getTime()) / 1000
  return baseReceipts + Math.floor(seconds * 0.25)
}

const ReceiptCounter = () => {
  const countUpRef = useRef(null)

  const { update } = useCountUp({
    ref: countUpRef,
    start: 0,
    end: baseReceipts,
    duration: 3,
    separator: ',',
    onEnd: () => updateNext()
  })

  const updateNext = () => {
    update(getReceiptNumber())
    setTimeout(updateNext, 1000)
  }

  return <span ref={countUpRef} style={{ width: 200 }} />
}
export default React.memo(ReceiptCounter)
